@import '.~@angular/material/theming';

@mixin iot-styles-reset-theme($theme) {
  $primary: map-get($theme, primary);
  $accent: map-get($theme, accent);
  $foreground: map-get($theme, foreground);

  a {
    color: mat-color($foreground, text);

    &:hover {
      color: mat-color($accent);
    }
  }

  code {
    color: mat-color($accent, lighter-contrast);
    background-color: mat-color($accent, lighter);
  }

  p {
    a {
      color: mat-color($accent);

      &:hover {
        color: mat-color($accent, darker);
      }
    }
  }
}
