@import "styles-variables";
@import "~bootstrap/dist/css/bootstrap.min.css";
@import "~bootstrap/scss/bootstrap-reboot";
@import "~bootstrap/scss/bootstrap-grid";
@import "styles-reset";
@import "~@fontsource/material-icons/index.css";
@import "~@angular/material/theming";
@include mat-core();
@import "./themes/default-theme";
@import "./themes/light-theme";
@import "./themes/black-theme";
@import "./themes/nature-theme";
@import "./themes/dark-theme";
@import "styles-reset.scss-theme";
@import "./app/app/app.component.scss-theme";
@import "./app/shared/big-input/big-input/big-input.component.scss-theme";
// @import "./app/features/about/about/about.component.scss-theme";
@mixin custom-components-theme($theme) {
  @include iot-styles-reset-theme($theme);
  @include iot-app-component-theme($theme);
  // @include iot-about-component-theme($theme);
  @include iot-big-input-component-theme($theme);
  // @include iot-notifications-component-theme($theme);
  // @include iot-todos-container-component-theme($theme);
  // @include iot-stock-market-container-component-theme($theme);
  // @include iot-parent-component-theme($theme);
  // @include iot-child-component-theme($theme);
}

.material-icons {
  font-family: "Material Icons";
  font-weight: normal;
  font-style: normal;
  font-size: 24px;
  /* Preferred icon size */
  display: inline-block;
  line-height: 1;
  text-transform: none;
  letter-spacing: normal;
  word-wrap: normal;
  white-space: nowrap;
  direction: ltr;
  /* Support for all WebKit browsers. */
  -webkit-font-smoothing: antialiased;
  /* Support for Safari and Chrome. */
  text-rendering: optimizeLegibility;
  /* Support for Firefox. */
  -moz-osx-font-smoothing: grayscale;
  /* Support for IE. */
  font-feature-settings: "liga";
}

.default-theme {
  @include angular-material-theme($iot-theme);
  @include custom-components-theme($iot-theme);
}

.light-theme {
  @include angular-material-theme($iot-light-theme);
  @include custom-components-theme($iot-light-theme);
}

.black-theme {
  @include angular-material-theme($iot-black-theme);
  @include custom-components-theme($iot-black-theme);
}

.nature-theme {
  @include angular-material-theme($iot-nature-theme);
  @include custom-components-theme($iot-nature-theme);
}

.backdropBackground {
  backdrop-filter: blur(2px);
}

.bottomSheetClass {
  backdrop-filter: blur(2px);
  width: 450px;
}

.mySnackbarClass {
  color: black !important;
  background-color: white !important;
}

.lineChart-shape-tooltip {
  background-image: url("https://hymonwebstore.blob.core.windows.net/linechartproperty/LineChartProperty.png") !important;
  background-size: cover !important;
  min-width: 35rem !important;
  min-height: 20rem !important;
  background-repeat: no-repeat !important;
  border-radius: 5px !important;
  border: solid 1px rgb(131, 131, 131);
  text-align: center;
}

// [mat-dialog-title] {
//   margin: -24px -24px 0px -24px !important;
//   padding: 10px 24px;
//   background: #369;
//   color: #fff;
//   cursor: move;
// }
.mat-tooltip {
  font-size: small;
}
